<template>
 <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <ProductsTable/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import sections from '@/apps/dido/views/defaultNavigationDrawerSections'
import ProductsTable from '@/apps/titan/components/tables/ProductsTable'

export default {
  name: 'JunoProductList',
  components: { Layout, ProductsTable },
  data () {
    return {
      sections: sections,
      title: this.$t('products.productsList')
    }
  }
}
</script>

<style scoped>

</style>
